import React from 'react';
import img from '../../assets/regions.jpg';
import UrkaneMap from '../../components/UkraineMap';
import OursClientsCarousel from '../../components/OursClientsCarousel';
import oursClientsData from '../../data/oursClientsData';
import OurTeam from '../../components/oursTeam/OurTeam';
import ourTeamData from '../../data/ourTeamData';
import FirstBlock from '../../components/FirstBlock';
import aboutData from '../../data/firstAbout';
import AboutUs from '../../components/aboutUs/AboutUs';
import { abouUsData } from '../../data/abouUsData';
import History from '../../components/history/History';

export default function About() {
  return (
    <div className='background-pages'> 
      <FirstBlock data={ aboutData }/>
      <AboutUs data={ abouUsData }/>
      <History id={'section-2'}/>
      {/* <OurTeam data={ ourTeamData } id="section-3"/> */}
      <UrkaneMap id={'section-4'} img={ img }/>
      <OursClientsCarousel 
        id={'section-5'} 
        data={ oursClientsData } 
      />
    </div>
  )
}
